<template>
    <div>
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="170"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn text small v-bind="attrs" v-on="on">
                    <span v-if="$vuetify.breakpoint.smAndUp">Período: {{ periodo }}</span>
                    <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-title>
                    <v-spacer/>
                        Filtrar período
                    <v-spacer/>
                </v-card-title>

                <v-divider/>

                <v-card-text>
                    <v-row class="my-4">
                        <v-col cols="12">
                            <v-select
                                @change="updatePeriodo"
                                :items="periodos"
                                v-model="periodo"
                                outlined
                                dense
                                label="Período"
                                hide-details
                                style="width: 200px;"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <div class="date-time-inputs">
                                <div class="input-wrapper">
                                    <input
                                        :disabled="periodo != 'Personalizado'"
                                        type="date"
                                        class="date-input"
                                        v-model="startDate"
                                        placeholder="Data de Início"
                                    >
                                    <label for="start-date">Data de Início</label>
                                </div>
                                <div class="input-wrapper">
                                    <input
                                        :disabled="periodo != 'Personalizado'"
                                        type="time"
                                        class="time-input"
                                        v-model="startHour"
                                        placeholder="Hora de Início"
                                    >
                                    <label for="end-time">Hora de Início</label>
                                </div>
                            </div>
                            <!-- <span class="mt-2 mx-1">à</span> -->
                        </v-col>

                         <v-col cols="12" sm="6">
                            <div class="date-time-inputs">
                                <div class="input-wrapper">
                                    <input
                                        :disabled="periodo != 'Personalizado'"
                                        type="date"
                                        class="date-input"
                                        v-model="endDate"
                                        placeholder="Data de Fim"
                                    >
                                    <label for="start-date">Data de Fim</label>
                                </div>
                                <div class="input-wrapper">
                                    <input
                                        :disabled="periodo != 'Personalizado'"
                                        type="time"
                                        class="time-input"
                                        v-model="endHour"
                                        placeholder="Hora de Fim"
                                    >
                                    <label for="end-time">Hora de Fim</label>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="gray" text @click="menu = false">Cancelar</v-btn>
                    <v-spacer/>
                    <v-btn color="primary" @click="update" class="ml-1">
                        Atualizar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: 'PeriodoFilter',

    props: {
        value: {
            type: Object,
        },

        defaultPeriodo: {
            type: String,
            default: 'Máximo',
        },
    },

    data: () => ({
        menu: false,
        startDate: '',
        startHour: '',
        endDate: '',
        endHour: '',
        periodo: 'Máximo',
        periodos: [
            'Hoje',
            'Ontem',
            'Últimos 7 dias',
            'Últimos 30 dias',
            'Esta semana',
            'Semana passada',
            'Este mês',
            'Mês passado',
            'Máximo',
            'Personalizado',
        ],
    }),

    mounted() {
        this.periodo = this.defaultPeriodo;
        this.updatePeriodo();

        const periodoEmitido = {
            start: `${this.startDate} ${this.startHour}`,
            end: `${this.endDate} ${this.endHour}`,
        };

        this.$emit('input', periodoEmitido);
    },

    watch: {
        defaultPeriodo: {
            immediate: true,
            handler(newPeriodo) {
                this.periodo = newPeriodo;
                this.updatePeriodo();
            }
        },
    },

    methods: {
        update() {
            if (!this.startDate || !this.startHour || !this.endDate || !this.endHour) {
                this.notify('Informe o período', 'warning');
                return;
            }

            const periodo = {
               start: `${this.startDate} ${this.startHour}`,
                end: `${this.endDate} ${this.endHour}`,
            };
            this.menu = false;
            this.$emit('input', periodo);
            this.$emit('update', periodo);
        },

        updatePeriodo() {
            if (this.periodo === 'Hoje') {
                this.startDate = this.moment().format('YYYY-MM-DD');
                this.startHour = '00:00';
                this.endDate = this.moment().format('YYYY-MM-DD');
                this.endHour = '23:59';
            } else if (this.periodo === 'Ontem') {
                this.startDate = this.moment().subtract(1, 'days').format('YYYY-MM-DD');
                this.startHour = '00:00';
                this.endDate = this.moment().subtract(1, 'days').format('YYYY-MM-DD');
                this.endHour = '23:59';
            } else if (this.periodo === 'Últimos 7 dias') {
                this.startDate = this.moment().subtract(7, 'days').format('YYYY-MM-DD');
                this.startHour = '00:00';
                this.endDate = this.moment().format('YYYY-MM-DD');
                this.endHour = '23:59';
            } else if (this.periodo === 'Últimos 30 dias') {
                this.startDate = this.moment().subtract(30, 'days').format('YYYY-MM-DD');
                this.startHour = '00:00';
                this.endDate = this.moment().format('YYYY-MM-DD');
                this.endHour = '23:59';
            } else if (this.periodo === 'Esta semana') {
                this.startDate = this.moment().startOf('isoWeek').format('YYYY-MM-DD');
                this.startHour = '00:00';
                this.endDate = this.moment().endOf('isoWeek').format('YYYY-MM-DD');
                this.endHour = '23:59';
            } else if (this.periodo === 'Semana passada') {
                this.startDate = this.moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
                this.startHour = '00:00';
                this.endDate = this.moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
                this.endHour = '23:59';
            } else if (this.periodo === 'Este mês') {
                this.startDate = this.moment().startOf('month').format('YYYY-MM-DD');
                this.startHour = '00:00';
                this.endDate = this.moment().endOf('month').format('YYYY-MM-DD');
                this.endHour = '23:59';
            } else if (this.periodo === 'Mês passado') {
                this.startDate = this.moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
                this.startHour = '00:00';
                this.endDate = this.moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
                this.endHour = '23:59';
            } else if (this.periodo === 'Máximo') {
                this.startDate = '2000-01-01';
                this.startHour = '00:00';
                this.endDate = this.moment().format('YYYY-MM-DD');
                this.endHour = '23:59';
            } else if (this.periodo === 'Personalizado') {
                this.startDate = '';
                this.startHour = '';
                this.endDate = '';
                this.endHour = '';
            }
        },
    },
}
</script>

<style scoped>
.date-time-inputs {
    display: flex;
    gap: 5px;
}

.time-input {
    width: 95px;
}

.date-input {
    width: 140px;
}

.date-input:disabled {
  background-color: #1e1e1e;
}

.time-input:disabled {
  background-color: #1e1e1e;
}

.input-wrapper {
    position: relative;
}

.input-wrapper label {
    color: #fff;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    background: #1e1e1e;
    padding: 0 4px;
    transition: all 0.1s;
    pointer-events: none;
}

.input-wrapper input {
    color: #fff;
    padding: 10px 10px 5px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #1e1e1e;
    transition: border-color 0.1s;
}

.input-wrapper input:focus {
    border: 2px solid #ced4da;
    border-color: #1976d2;
    outline: none;
}

.input-wrapper input:focus + label {
    color: #1976d2;
}

.input-wrapper input:not(:placeholder-shown) + label {
    top: 3px;
    left: 8px;
    font-size: 12px;
}

.input-wrapper input::placeholder {
    opacity: 0;
}

.input-wrapper input:disabled + label {
  color: #fff;
}
</style>